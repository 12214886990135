import React from "react"
import { Dialog } from "@headlessui/react"
import { shippingOptions } from "../../data/shipping"

const ShippingModal = ({ isOpen, closeModal, onConfirm, loading }) => {
  const [selectedShipping, setSelectedShipping] = React.useState('pickup')

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            Select Shipping Option
          </Dialog.Title>

          <div className="mt-4">
            <div className="space-y-2">
              {shippingOptions.map((option) => (
                <div
                  key={option.id}
                  className={`relative flex items-center p-4 cursor-pointer rounded-lg border transition-all ${selectedShipping === option.id
                      ? 'border-lime-800 bg-lime-50'
                      : 'border-gray-200 hover:border-gray-300'
                    }`}
                  onClick={() => setSelectedShipping(option.id)}
                >
                  <input
                    type="radio"
                    name="shipping-option"
                    value={option.id}
                    checked={selectedShipping === option.id}
                    onChange={() => setSelectedShipping(option.id)}
                    className="h-4 w-4 text-lime-800 focus:ring-lime-800 border-gray-300"
                  />
                  <label className="ml-3 flex flex-1 justify-between text-sm font-medium text-gray-900">
                    <div>
                      <span>{option.shipping_rate_data.display_name}</span>
                      <p className="text-sm text-gray-500">
                        {option.shipping_rate_data.delivery_estimate.minimum.value}-
                        {option.shipping_rate_data.delivery_estimate.maximum.value} business days
                      </p>
                    </div>
                    <span>
                      {option.shipping_rate_data.fixed_amount.amount === 0
                        ? 'FREE'
                        : `$${(option.shipping_rate_data.fixed_amount.amount / 100).toFixed(2)}`}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-white bg-lime-600 rounded-md hover:bg-lime-700 disabled:opacity-50"
              onClick={() => onConfirm(selectedShipping)}
              disabled={loading}
            >
              {loading ? "Processing..." : "Continue to Checkout"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ShippingModal